<!-- 编辑宗教场所 -->
<template>
  <el-dialog
    title="编辑宗教场所"
    :visible.sync="editShow"
    width="50%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <ax-form ref="formBox" :formBuilder="formBuilder">
      <div slot="map" style="display: flex">
        <ax-button type="#faad14" @click="mapShow">地图标绘</ax-button>
        <el-dialog
          title="地图标绘"
          width="1000px"
          :visible.sync="dialogVisible"
          :z-index="12"
          :destroy-on-close="true"
          :close-on-click-modal="false"
        >
          <position
            ref="position"
            @close="dialogVisible = false"
            @locationUpdated="handleLocationUpdate"
          />
        </el-dialog>
      </div>
      <div slot="gridId" style="display: flex">
        <a-input v-if="mount" v-model="mount" @click="changeone"></a-input>
        <a-cascader
          v-else
          :options="options"
          change-on-select
          placeholder="请选择所属网格"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="onChange"
          :z-index="666"
          :model="gridId"
          v-decorator="[
            'gridId',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />

        <!-- -->
      </div>
      <!-- 地理位置 -->
      <div slot="address">
        <a-input
          v-if="address1"
          v-model="address1"
          @click="changetwo"
        ></a-input>
        <a-cascader
          v-else
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :options="options1"
          :load-data="loadData"
          placeholder="请选择现住区域"
          @change="onChange1"
          :model="residenceCode"
          v-decorator="[
            'residenceCode',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "./api";
import position from "./position.vue";

const type = [
  { label: "寺庙", value: "寺庙" },
  { label: "教堂", value: "教堂" },
  { label: "道观", value: "道观" },
  { label: "其他", value: "其他" },
];
const tags = [
  { label: "祈祷", value: "祈祷" },
  { label: "典礼", value: "典礼" },
  { label: "研究", value: "研究" },
  { label: "慈善事业", value: "慈善事业" },
  { label: "旅游", value: "旅游" },
  { label: "其他", value: "其他" },
];
const safetyDesc = [
  { label: "无", value: "无" },
  { label: "居民投诉", value: "居民投诉" },
  { label: "噪音", value: "噪音" },
  { label: "污染", value: "污染" },
  { label: "其他", value: "其他" },
];
const formList = [
  {
    label: "场所名称",
    type: "",
    model: "name",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "场所名称格式不正确",
      },
    ],
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格", showTime: true },
    col: { span: 24 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },

  {
    label: "场所负责人",
    type: "",
    model: "managerName",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 20,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "场所负责人格式不正确",
      },
    ],
  },

  {
    label: "联系方式",
    type: "",
    model: "contact",
    options: { placeholder: "请输入", showTime: true, maxLength: 11 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^1[3-9]\d{9}$/,
        message: "联系方式格式不正确",
      },
    ],
  },
  {
    label: "位置信息",
    type: "",
    model: "address",
    options: { placeholder: "请输入", showTime: true },
    col: { span: 24 },
  },
  {
    label: "宗教类型",
    type: "select",
    model: "type",
    options: {
      options: type,
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "宗教类型不能为空" }],
  },

  {
    label: "场所属性",
    type: "select",
    model: "attribute",
    options: {
      options: tags,
      placeholder: "场所属性",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "场所属性不能为空" }],
  },
  {
    label: "开始营业时间",
    type: "timePicker",
    model: "startTime",
    col: { span: 12 },
    options: { placeholder: "请输入", showTime: true },
  },
  {
    label: "结束营业时间",
    type: "timePicker",
    model: "endTime",
    col: { span: 12 },
    options: { placeholder: "请输入", showTime: true },
  },
  {
    label: "社区影响",
    type: "select",
    model: "safetyDesc",
    options: {
      options: safetyDesc,
      mode: "multiple",
      optionFilterProp: "label",
      labelInValue: false,
      placeholder: "请选择",
      showTime: true,
    },
    col: { span: 24 },
  },
  {
    label: "是否为文化遗产",
    type: "radio",
    model: "hasInfluence",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
      placeholder: "请输入",
      showTime: true,
    },
    col: { span: 24 },
  },
  {
    label: "场所纬度",
    type: "",
    model: "latitude",
    options: { placeholder: "请标绘", showTime: true, disabled: true },
    col: { span: 12 },
    rules: [{ required: false, message: "场所纬度不能为空" }],
  },
  {
    label: "场所经度",
    type: "",
    model: "longitude",
    options: { placeholder: "请标绘", showTime: true, disabled: true },
    col: { span: 12 },
    rules: [{ required: false, message: "场所经度不能为空" }],
  },
  {
    label: "地图位置",
    type: "",
    model: "map",
    col: { span: 24 },
  },
];
export default {
  components: {
    position,
  },
  data() {
    return {
      api,
      gridId: "",
      gridAddress: "",
      options: [],
      openTime: "",
      latitude: "",
      longitude: "",
      id: "",
      saveType: false,
      mount: "",
      latitude1: "",
      longitude1: "",
      initialGridId: "",
      initialGridAddress: "",
      editShow: false,
      options1: [],
      domicileValue: {},
      address: "",
      locations: {},
      newObj: {},
      address1: "",
      residenceCode: "",
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
    this.getRegionInfo();
  },
  methods: {
    changeone() {
      this.mount = "";
      this.gridId = "";
    },
    changetwo() {
      this.address1 = "";
      this.residenceCode = "";
    },
    handleLocationUpdate(locationData) {
      // 接收并处理传递过来的经纬度值
      // console.log("收到的经度值:", locationData.lng);
      this.latitude = locationData.lng;
      // console.log("收到的纬度值:", locationData.lat);
      this.longitude = locationData.lat;
      this.newObj.longitude = locationData.lat;
      this.newObj.latitude = locationData.lng;
    },
    // 获取省级行政区划
    async getRegionInfo() {
      const res = await api.getRegionInfo();
      // console.log(res, "省级");
      this.options1 = res.data.map((res1) => {
        return {
          label: res1.areaName,
          value: res1.provinceCode,
          isLeaf: false,
        };
      });
    },
    // 级联选择器改变的回调(地理位置)
    onChange1(value, e) {
      // console.log(value, e);
      const address1 = e.map((item) => item.label).join("/");
      this.address1 = address1;
      // console.log(this.address1);
      //传最后一个id
      this.residenceCode = value[value.length - 1];
    },
    // 分批获取下一级数据
    async loadData(selectedOptions) {
      // console.log(selectedOptions, "361");
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      // 判断当前选项的级别
      switch (selectedOptions.length) {
        case 1:
          // 加载第二级数据
          const res2 = await api.getProvinceRegionInfo(targetOption.value);
          // console.log(res2, "370");
          targetOption.children = res2.data.map((res1) => ({
            label: res1.areaName,
            value: res1.cityCode,
            isLeaf: false,
          }));
          break;
        case 2:
          // 加载第三级数据
          const res3 = await api.getCityRegionInfo(targetOption.value);
          // console.log(res3, "380");
          targetOption.children = res3.data.map((res1) => ({
            label: res1.areaName,
            value: res1.countyCode,
            isLeaf: false,
          }));
          break;
        case 3:
          // 加载第四级数据
          const res4 = await api.getCountyRegionInfo(targetOption.value);
          // console.log(res4, "390");
          targetOption.children = res4.data.map((res1) => ({
            label: res1.areaName,
            value: res1.townCode,
            isLeaf: true, // 最后一级为叶子节点
          }));
          break;
        default:
          break;
      }
      targetOption.loading = false;
      this.options = [...this.options];
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    //查看
    async getData() {
      const res = await api.getById(this.id);
      this.$refs.formBox.setFieldsValue(res.data); //赋值
      // console.log(res.data.gridId);
      // console.log(res.data.gridAddress);
      // 将字符串拆分为数组
      // this.mount = [res.data.gridId + ""];
      this.openTime = res.data.openTime;
      let [startTime, endTime] = this.openTime.split(" ~ ");
      // console.log(startTime);
      //单独传值
      this.$refs.formBox.setFieldsValue({
        startTime: startTime,
        endTime: endTime,
      });
      this.mount = res.data.gridAddress;
      // 保存初始值
      this.initialGridId = res.data.gridId;
      this.latitude1 = res.data.latitude;
      this.locations.latitude = res.data.latitude;
      this.locations.longitude = res.data.longitude;
      this.longitude1 = res.data.longitude;
      this.initialGridAddress = res.data.gridAddress;
      this.address1 = res.data.address;
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // console.log(value, "value");
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      const address = e.map((item) => item.label).join("/");
      this.gridId = lastId;
      this.gridAddress = address;
      // console.log(typeof this.gridId);
      // console.log(this.gridId, "id");
    },
    // 打开弹窗
    openModal(record = {}) {
      this.editShow = true;
      this.id = record.id;
      // console.log(this.id);
      this.$nextTick(() => {
        this.getData();
      });
    },

    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.kind = 4;
        value.id = this.id;
        value.openTime = `${value.startTime} ~ ${value.endTime}`;
        // 判断是否有新的 gridId 值，如果有则使用新值，没有则使用初始值
        if (this.gridId) {
          value.gridId = this.gridId;
        } else {
          value.gridId = this.initialGridId;
        }

        // 判断是否有新的 gridAddress 值，如果有则使用新值，没有则使用初始值
        if (this.gridAddress) {
          value.gridAddress = this.gridAddress;
        } else {
          value.gridAddress = this.initialGridAddress;
        }
        if (this.address1) {
          value.address = this.address1;
        } else {
          value.address = this.address1;
        }
        if (this.latitude) {
          value.latitude = this.latitude;
        } else {
          value.latitude = this.latitude1;
        }
        if (this.longitude) {
          value.longitude = this.longitude;
        } else {
          value.longitude = this.longitude1;
        }
        value.safetyDesc = value.safetyDesc || "";
        value.openTime = value.openTime || "";
        // console.log(value);
        this.saveType = true; // 验证通过后再设置
        try {
          const res = await api.update(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("编辑成功");
            // 提交成功后关闭弹窗
            this.saveType = false; // 验证通过后再设置
            this.closeDialog();
            this.refreshTable();
          } else {
            this.$message.error("编辑失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("编辑失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.editShow = false;
      this.newObj = {};
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
      // console.log("refreshTable");
    },
    mapShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.newObj.latitude) {
          this.$refs.position.getlocation(this.newObj);
        } else {
          // console.log(1111);
          this.$refs.position.getlocation(this.locations);
        }
      });
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
</style>
    